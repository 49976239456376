<template>
    <div>
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
                    <el-row>
                        <el-form-item label="优惠券名称:">
                            <el-input></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                            <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border style="width:100%;" @selection-change="handleSelectionChange" @current-change="handleCurrentSelectChange">
                    <el-table-column v-if="mode === 'single'" width="36">
                        <template #default="scope">
                            <el-radio :label="scope.row" v-model="singleSelect"><i></i></el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="150"></el-table-column>
                    <el-table-column prop="coupon_name" label="优惠券名称"></el-table-column>
                    <el-table-column prop="coupon_type" label="类型" width="150">
                        <template #default="scope">
                            <span :style="{color:(params_map['coupon_type']&&params_map['coupon_type'][scope.row.coupon_type])?params_map['coupon_type'][scope.row.coupon_type].color:''}">{{(params_map['coupon_type'] && params_map['coupon_type'][scope.row.coupon_type])?params_map['coupon_type'][scope.row.coupon_type].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="with_money" label="优惠金额" align="right"></el-table-column>
                    <el-table-column prop="price_down_limit" label="使用价格下限" align="right"></el-table-column>
                    <el-table-column prop="" label="已发放/总量" align="center">
                        <template #default="scope">
                            <div style="text-align: center;">
                                <span>{{scope.row.sends}}</span>/<span>{{scope.row.quota}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="创建时间" :formatter="elTimeStampFormat"></el-table-column>
                    <el-table-column prop="" label="操作" width="160">
                        <template #default="scope">
<!--                            <el-button type="text" size="mini" @click="onViewTap(scope.row)">查看</el-button>-->
                            <router-link target="_blank" :to="`/b/coupon-detail?action=view&id=${scope.row.id}`" style="color:#409EFF;">查看</router-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
            <div style="display:flex;flex-direction:row;justify-content:flex-end;align-items: center;margin-top:15px;">
                <el-button @click="handleCancelTap" size="mini">取消</el-button>
                <el-button v-if="scene === 'send'" @click="handleSubmitTap" size="mini" type="primary">发送</el-button>
                <el-button v-else-if="scene === 'select'" @click="handleOnSelectConfirmTap" size="mini" type="primary">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {getList,sendToUser} from '@/api/hado/coupon'
    import {getParams} from '@/api/hado/param'
    import {formatTimestamp} from "@/utils/time";
    const param_list = ['coupon_type']
    export default {
        name: "coupon-select-list",
        props:{
            mode:{
                type:String,
                default:'single'
            },//单选或多选 single  / multiple
            userId:{
                type:String,
                default:''
            },
            scene:{
                type:String,
                default:'select',//select选择优惠券 send 发送优惠券 历史问题
            },
            //用于回调
            coupon_record_id:{
                type:String,
                default:''
            },
            //用于查询条件
            coupon_type:{
                type:String,
                default:''
            },
            //绑定字段 用于回调
            field:{
                type:String,
                default:''
            }
        },
        data() {
            return {
                loading: false,
                search_form_name: 'coupon_search',
                page_option: {page: 1, size: 30, total: 0},
                search_option: {},
                page_list: [],
                params: {},
                params_map_pure: {},
                params_map: {},

                selectionList:[],//多选的结果
                singleSelect:{},//单选的结果
            }
        },
        created() {
            this.initPage()
        },
        methods:{
            initPage() {
                this.initParams().then(() => {
                    this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams() {
                return new Promise((resolve, reject) => {
                    getParams(param_list.join(','), 'en').then(resp => {
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl => {
                            console.log('pl:', pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm => {
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key] = pm.value
                                tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value] = pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err) => {
                        if (err) {
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt, searchOpt) {
                this.loading = true;
                getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
                    this.loading = false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearchTap() {
                let searchOpt = Object.assign({}, this.search_option)
                this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
            },
            handleSearchClear(formName) {
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize) {
                this.getPageList({page: 1, size: pageSize}, this.search_option);
            },
            handleCurrentChange(pageIndex) {
                this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
            },
            handleSelectionChange(val) {
                this.selectionList = val;
                console.log(this.selectionList)
            },
            handleCurrentSelectChange(row){
                console.log('handleCurrentChange')
                if(this.mode === 'single'){
                    this.singleSelect = row;
                    this.selectionList = [this.singleSelect]
                }
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            handleCancelTap(){
                this.$emit("onTellDialogClose")
            },
            handleSubmitTap(){
                console.log(this.singleSelect)
                this.$confirm("确定发送优惠券?",{
                    confirmButtonText:'确定发送',
                    cancelButtonText:'取消',
                    type:'warning'
                }).then(()=>{
                    sendToUser(this.singleSelect.id,this.userId).then(resp=>{
                        console.log(resp)
                        this.$emit('onTellDialogClose',{})
                    }).catch((rerr)=>{
                        console.error(rerr);
                    })
                })

                // let result = {user_list:this.selectionList}
                // this.$emit('onTellDialogClose',result)
            },

            handleOnSelectConfirmTap(){
                //看下这货选了没有就提示
                this.$confirm('确定选择当前优惠券?',{
                    confirmButtonText:'确定',
                    cancelButtonText:'容朕想想',
                    type:'warning',
                }).then(()=>{
                    this.$emit('onCouponSelectConfirm',{coupon_id:this.singleSelect.id,field:this.field})
                }).catch(err=>{
                    console.error(err)
                })
            },
        }
    }
</script>

<style scoped>

</style>
